import { classNames } from "@calcom/lib";

export function Label(props: JSX.IntrinsicElements["label"]) {
  return (
    <label
      {...props}
      className={classNames("font-body dark:text-neutral-light-2 mb-2 block text-base", props.className)}>
      {props.children}
    </label>
  );
}
